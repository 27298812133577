import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { useAuth } from '@workos-inc/authkit-react';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Typography } from '@botco/library';
import botcoLogo from '~/assets/img/botco.png';
import { SpinnerLoader } from '~/components/SpinnerLoader';
import { useApi } from '~/hooks/useApi';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { setSelectedAgent } from '~/redux/actions/conversationNew';

import { WorkOSOrganizations } from './components/WorkOSOrganizations';

const useStyle = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
  },
  header: {
    height: 60,
    boxShadow: theme.shadows[3],
    padding: theme.spacing(0, 6),
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    backgroundColor: theme.palette.common.white,
  },
  logo: {
    height: 40,
    width: 120,
    maxWidth: '200px',
  },
  container: {
    padding: theme.spacing(4, 3, 5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  card: {
    maxWidth: 560,
    width: '100%',
  },
  tile: {
    marginBottom: theme.spacing(3),
    userSelect: 'none',
  },
  optionsContainer: {
    maxHeight: 420,
    position: 'relative',
    height: 'fit-content',
    border: `1px solid ${theme.palette.grey[600]}`,
    borderRadius: 12,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  searchBox: {
    height: 48,
    minHeight: 48,
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[600]}`,

    '& input': {
      width: '100%',
      border: 'none',
      outline: 'none',
      fontSize: 16,
      height: '100%',
      backgroundColor: 'transparent',
    },
  },
  loadingWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
    cursor: 'not-allowed',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
  },
}));

export const WorkOSSwitchOrganization: React.FC = () => {
  const user = useCurrentUser();
  const classes = useStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { user: wokosUser, switchToOrganization } = useAuth();

  const [search, setSearch] = React.useState('');
  const [isSwitchingOrg, setIsSwitchingOrg] = React.useState(false);

  const { data, isLoading: isLoadingData } = useApi(
    ['workos-user-organizations', user.user_id, wokosUser?.id],
    (Api) =>
      Api.workos.getUserOrganizations({
        userId: user.user_id?.toString(),
        workos_user_id: wokosUser?.id!,
      }),
    { enabled: !!user.user_id && !!wokosUser?.id }
  );

  const organizations =
    data?.filter((org) =>
      org.name.toLowerCase().includes(search.toLowerCase())
    ) ?? [];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleClearSearch = () => {
    setSearch('');
    inputRef.current?.focus();
  };

  const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && organizations.length === 1) {
      handleSelectOrg(organizations[0].id);
    }
  };

  const handleSelectOrg = async (orgId: string) => {
    setIsSwitchingOrg(true);
    dispatch(setSelectedAgent({}));
    await switchToOrganization({ organizationId: orgId });
    history.push('/');
  };

  const isLoading = isLoadingData || isSwitchingOrg;

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <img src={botcoLogo} alt="logo" className={classes.logo} />
      </header>
      <div className={classes.container}>
        <div className={classes.card}>
          <Typography
            variant="h3"
            align="center"
            color="black"
            className={classes.tile}
          >
            Select an organization to continue
          </Typography>
          <div className={classes.optionsContainer}>
            <div className={classes.searchBox}>
              <SearchIcon />
              <input
                ref={inputRef}
                autoFocus
                disabled={isSwitchingOrg}
                placeholder="Search for an organization"
                value={search}
                onChange={handleSearch}
                onKeyDown={handleEnter}
              />
              {search && (
                <IconButton size="small" onClick={handleClearSearch}>
                  <CloseIcon />
                </IconButton>
              )}
            </div>
            <WorkOSOrganizations
              organizations={organizations}
              isLoading={isLoading}
              onSelectOrg={handleSelectOrg}
            />
            {isLoading && (
              <div className={classes.loadingWrapper}>
                <SpinnerLoader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
