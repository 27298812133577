import * as yup from '~/utils/yup';

export const AIErrorBlocksSchema = yup
  .object({
    errorBlockId: yup.safeNumber().notRequired(),
    fallbackBlockId: yup.safeNumber().notRequired(),
    contentErrorBlockId: yup.safeNumber().notRequired(),
    rateLimitErrorBlockId: yup.safeNumber().notRequired(),
  })
  .default({})
  .transform((value) => {
    if (!value) return {};

    if (yup.string().isJson().isValidSync(value)) {
      return JSON.parse(value ?? '{}');
    }

    return value;
  });

// eslint-disable-next-line no-redeclare
export type AIErrorBlocksSchema = yup.InferType<typeof AIErrorBlocksSchema>;
