import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { routes } from '~/constants/routes';
import { PersonalDashboard } from '~/containers/PersonalDashboard';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';

import { Header } from './Header/Header';
import { IdleDialog } from './IdleDialog';
import { SideNav } from './SideNav/SideNav';
import { useLayout } from './useLayout';
import { SpinnerLoader } from '../SpinnerLoader';

const Blocks = React.lazy(() =>
  import('~/containers/Blocks/Blocks').then((module) => ({
    default: module.Blocks,
  }))
);

const Broadcast = React.lazy(() =>
  import('~/containers/Broadcast').then((module) => ({
    default: module.Broadcast,
  }))
);

const Deployments = React.lazy(() =>
  import('~/containers/Launch/Deployments').then((module) => ({
    default: module.Deployments,
  }))
);
const Deploy = React.lazy(() =>
  import('~/containers/Launch/Deploy').then((module) => ({
    default: module.Deploy,
  }))
);
const Training = React.lazy(() =>
  import('~/containers/Training').then((module) => ({
    default: module.Training,
  }))
);

const AuditTrail = React.lazy(() =>
  import('~/containers/AuditTrail/AuditTrail').then((module) => ({
    default: module.AuditTrail,
  }))
);

const AITraining = React.lazy(() =>
  import('~/containers/Training/AITraining').then((module) => ({
    default: module.AITraining,
  }))
);

const Dashboard = React.lazy(() =>
  import('~/containers/Dashboard/Dashboard').then((module) => ({
    default: module.Dashboard,
  }))
);
const KnowledgeBase = React.lazy(() =>
  import('~/containers/KnowledgeBase/KnowledgeBase').then((module) => ({
    default: module.KnowledgeBase,
  }))
);
const ConversationsListPage = React.lazy(
  () =>
    import('~/containers/Conversations/ConversationsList/ConversationsListPage')
);
const AgentPreview = React.lazy(() =>
  import('~/containers/Conversations/ConversationPreview/AgentPreview').then(
    (module) => ({ default: module.AgentPreview })
  )
);
const OverView = React.lazy(() =>
  import('~/containers/Conversations/OverView').then((module) => ({
    default: module.Overview,
  }))
);
const NewForm = React.lazy(() =>
  import('~/containers/Conversations/NewForm/Form').then((module) => ({
    default: module.NewForm,
  }))
);
const Contacts = React.lazy(() =>
  import('~/containers/Contacts').then((module) => ({
    default: module.Contacts,
  }))
);
const LegacyContacts = React.lazy(() =>
  import('~/containers/Contacts/Legacy/Contacts/Contacts').then((module) => ({
    default: module.Contacts,
  }))
);
const LegacyContactDetails = React.lazy(() =>
  import('~/containers/Contacts/Legacy/ContactDetails/ContactDetails').then(
    (module) => ({
      default: module.ContactDetails,
    })
  )
);
const HelpCenter = React.lazy(() =>
  import('~/containers/HelpCenter').then((module) => ({
    default: module.HelpCenter,
  }))
);

const TrainingDetails = React.lazy(() =>
  import('~/containers/Training/TrainingDetails').then((module) => ({
    default: module.TrainingDetails,
  }))
);

const InstaStackAIPlayground = React.lazy(() =>
  import('~/containers/InstaStack/AIPlayground').then((module) => ({
    default: module.InstaStackAIPlayground,
  }))
);

const InstaStackUrlData = React.lazy(() =>
  import('~/containers/InstaStack/Data/components/UrlData').then((module) => ({
    default: module.InstaStackUrlData,
  }))
);

const InstaStackData = React.lazy(() =>
  import('~/containers/InstaStack/Data').then((module) => ({
    default: module.InstaStackData,
  }))
);

const AuthoringWizard = React.lazy(() =>
  import('~/containers/AuthoringWizard').then((module) => ({
    default: module.AuthoringWizard,
  }))
);

const WorkOsUserTable = React.lazy(() =>
  import('~/containers/Settings/WorkOsUserTable').then((module) => ({
    default: module.WorkOsUserTable,
  }))
);

const useStyles = makeStyles((theme) => ({
  loader: {
    width: 200,
    height: 200,
    top: 'calc(50% - 100px)',
    position: 'absolute',
    left: 'calc(50% - 100px)',
  },
  container: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },
  draggableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  routeContainer: {
    flexGrow: 1,
    minHeight: 0,
    backgroundColor: theme.palette.grey['300'],

    padding: theme.spacing(5, 6, 3),
    height: '100%',
    overflow: 'auto',
    position: 'relative',
  },
}));

export const Layout = () => {
  const { selectedAgent } = useSelectedChatbot();
  const { showComponent, logout, openIdleDialog } = useLayout();
  const classes = useStyles();

  if (!showComponent) {
    return (
      <div className={classes.container}>
        <SideNav />
        <SpinnerLoader className={classes.loader} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <SideNav />
      <div className={classes.draggableContainer}>
        <Header />
        <div id="main-container" className={classes.routeContainer}>
          {openIdleDialog && <IdleDialog onLogout={logout} open />}
          <React.Suspense
            fallback={<SpinnerLoader className={classes.loader} />}
          >
            <Switch>
              <Route
                exact
                path={routes.chatbots.path}
                component={ConversationsListPage}
              />
              <Route
                exact
                path={routes.chatbotDetails.path}
                component={AgentPreview}
              />
              <Route
                exact
                path={routes.chatbotOverview.path}
                component={OverView}
              />
              <Route path={routes.forms.path} component={AgentPreview} />
              <Route
                path={[routes.chatbotForm.path, routes.chatbotNewForm.path]}
                component={NewForm}
              />
              <Route
                path={routes.chatbotSequence.path}
                component={AgentPreview}
              />
              <Route path={routes.dashboardV1.path} component={Dashboard} />
              <Route
                path={routes.dashboard.path}
                component={PersonalDashboard}
              />
              <Route path={routes.newDeploymentBeta.path} component={Deploy} />
              <Route path={routes.deployments.path} component={Deployments} />
              <Route path={routes.blocks.path} exact component={Blocks} />
              <Route path={routes.helpCenter.path} component={HelpCenter} />
              <Route
                path={routes.knowledgeBase.path}
                component={KnowledgeBase}
              />
              <Route path={routes.contacts.path} component={Contacts} />
              <Route
                path={routes.contactLegacyDetails.path}
                component={LegacyContactDetails}
              />
              <Route
                path={routes.contactsLegacy.path}
                component={LegacyContacts}
              />
              <Route
                path={routes.aiTraining.path}
                exact
                component={AITraining}
              />
              <Route path={routes.training.path} exact component={Training} />
              <Route
                path={routes.trainingDetails.path}
                exact
                component={TrainingDetails}
              />
              <Route
                path={routes.instaStackAIPlayground.path}
                exact
                component={InstaStackAIPlayground}
              />
              <Route
                path={routes.instaStackData.path}
                exact
                component={InstaStackData}
              />
              <Route
                path={routes.instaStackUrlData.path}
                exact
                component={InstaStackUrlData}
              />
              <Route
                path={routes.authoringWizard.path}
                component={AuthoringWizard}
              />
              <Route path={routes.broadcast.path} component={Broadcast} />
              <Route path={routes.auditTrail.path} component={AuditTrail} />
              <Route path={routes.users.path} component={WorkOsUserTable} />
              <Redirect
                to={
                  selectedAgent?.id
                    ? routes.dashboard.get(selectedAgent.id)
                    : routes.chatbots.get()
                }
              />
            </Switch>
          </React.Suspense>
        </div>
      </div>
    </div>
  );
};
