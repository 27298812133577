import { makeStyles, TablePagination, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';

import { Table, TableCell, Tooltip, Typography } from '@botco/library';
import { TableSkeleton } from '~/components/TableSkeleton';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';
import { paginate } from '~/utils/paginate';

import { DashboardButtonTooltip } from './components/DashboardButtonTooltip';
import { DashboardCard } from '../components/Card/DashboardCard';
import { useDashboardQueryServiceContext } from '../Provider/DasboardQueryServiceProvider';

const PAGE_SIZE = 5;

const useStyles = makeStyles(() => ({
  tableContainer: {
    height: 374,
  },
  table: {
    tableLayout: 'fixed',
  },
  ellipsisText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
    display: 'block',
    userSelect: 'none',
  },
}));

export const DashboardButtonClicks = () => {
  const classes = useStyles();

  const [page, setPage] = React.useState(1);
  const { conditions } = useDashboardQueryServiceContext();

  const { isLoading, data } = useQueryServiceApi(
    ['dashboard', 'v2', conditions, 'Buttons'],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: [QueryServiceMetricsConfig.ButtonClicks],
        },
      }),
    { enabled: Boolean(conditions) }
  );

  const { data: buttonClicks, totalItems } = paginate(
    data?.widget_clicks?.content?.counts_by_text_reference ?? [],
    page,
    PAGE_SIZE
  );

  return (
    <DashboardCard title="Button Clicks">
      <Table
        data={buttonClicks}
        getKey={(item) => `${item.reference}-${item.text}`}
        variant="transparent"
        className={classes.tableContainer}
        classes={{ root: classes.table }}
        headerColumns={
          <>
            <TableCell width="180px">Button Name</TableCell>
            <TableCell align="center" width="80px">
              Count
            </TableCell>
          </>
        }
        renderRow={(item) => (
          <>
            <TableCell>
              <Tooltip
                title={<DashboardButtonTooltip item={item} />}
                interactive
              >
                <Typography color="black">{item.text}</Typography>
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Typography color="black">{item.count}</Typography>
            </TableCell>
          </>
        )}
      >
        {isLoading && <TableSkeleton rows={2} columns={2} />}
        <TableRow>
          <TableCell style={{ fontWeight: 700, borderBottom: 0 }}>
            TOTAL
          </TableCell>
          <TableCell
            align="center"
            style={{ fontWeight: 700, borderBottom: 0 }}
          >
            {isLoading ? <Skeleton /> : data?.widget_clicks?.count}
          </TableCell>
        </TableRow>
      </Table>
      {totalItems > 0 && (
        <TablePagination
          rowsPerPageOptions={[]}
          count={totalItems}
          colSpan={5}
          component="div"
          rowsPerPage={PAGE_SIZE}
          page={page - 1}
          onPageChange={(_, page) => setPage(page + 1)}
        />
      )}
    </DashboardCard>
  );
};
