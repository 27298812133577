import { Box } from '@material-ui/core';
import * as React from 'react';
import type { QueryStatus } from 'react-query';
import { useHistory } from 'react-router-dom';

import { Attributes } from '~/components/Attributes';
import { SpinnerLoader } from '~/components/SpinnerLoader';
import { useApi } from '~/hooks/useApi';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useGetDeployments } from '~/hooks/useGetDeployments';
import { useQueryParams } from '~/hooks/useQueryParams';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { isValidAgentId } from '~/utils/agentUtils';
import { DashboardSettings } from '~/utils/http/dashboard/types';
import { DeployType } from '~/utils/http/deployment/types';

import {
  DashboardFiltersSchema,
  DashboardFiltersSchemaType,
  DataOnViewAll,
  GetDataOnViewAll,
  toUrlParams,
} from './DashboardProvider.types';
import {
  getDeploymentOptions,
  getFiltersFromStorage,
  saveFiltersToStorage,
} from './DashboardProvider.utils';

type DashboardContextType = {
  deploymentOptions: { label: string; value: string }[];
  deployTypeOptions: { label: string; value: string }[];
  agentId: number;
  filters: DashboardFiltersSchemaType;
  setFilters: (filters: Partial<DashboardFiltersSchemaType>) => void;
  status: QueryStatus;
  settings: DashboardSettings;
  isLoadingSettings: boolean;
  getDataOnViewAll: GetDataOnViewAll;
};

const Context = React.createContext<DashboardContextType>({
  deploymentOptions: [],
  deployTypeOptions: [],
  filters: DashboardFiltersSchema.getDefault() as DashboardFiltersSchemaType,
  setFilters: () => undefined,
  status: 'idle',
  agentId: 0,
  settings: { hiddenMetrics: [] },
  isLoadingSettings: false,
  getDataOnViewAll: () => ({}) as any,
});

export const DashboardProvider = ({
  children,
  initialFilters,
}: React.PropsWithChildren<{
  initialFilters?: Partial<DashboardFiltersSchemaType>;
}>) => {
  const user = useCurrentUser();
  const { selectedAgent } = useSelectedChatbot();

  const history = useHistory();
  const agentId = selectedAgent?.id;
  const params = useQueryParams();

  const initialValues = React.useMemo(
    () => ({
      ...DashboardFiltersSchema.getDefault(),
      ...initialFilters,
      ...(getFiltersFromStorage(user.account_id) ?? {}),
    }),
    [initialFilters, user.account_id]
  );

  const state = DashboardFiltersSchema.validateSync(
    {
      ...initialValues,
      ...Object.fromEntries(params.entries()),
    },
    { stripUnknown: true }
  );

  const setState = React.useCallback(
    (partialState: Partial<DashboardFiltersSchemaType>) => {
      const newParams = new URLSearchParams(history.location.search);

      const nullOrUndefinedValues = Object.entries(partialState)
        .filter(([key, value]) =>
          key === 'attributes'
            ? !partialState.attributes?.conditions?.length
            : value === null || typeof value === 'undefined'
        )
        .map(([key]) => key);

      const newState = toUrlParams(partialState);

      Object.entries(newState).forEach(([key, value]) => {
        newParams.set(key, value);
      });

      nullOrUndefinedValues.forEach((key) => newParams.delete(key));

      saveFiltersToStorage(
        user.account_id,
        Object.fromEntries(
          newParams.entries()
        ) as unknown as DashboardFiltersSchemaType
      );

      history.replace({
        search: newParams.toString(),
      });
    },
    [history, user.account_id]
  );

  const prevDeployType = React.useRef(state.deployType);

  const { data: settingsData, isLoading: isLoadingSettings } = useApi(
    ['DashboardHiddenMetrics', selectedAgent?.account_id, selectedAgent?.id],
    (Api) =>
      Api.dashboard.overview.getDashboardSettings({
        accountId: selectedAgent?.account_id!,
        agentId: selectedAgent?.id!,
      }),
    { retry: false, enabled: isValidAgentId(selectedAgent?.id) }
  );

  const { data: deployments = [], status } = useGetDeployments({
    agentId,
    onSuccess: (data) => {
      if (state.deployId) return;

      const shouldSetDefaultValue = !state.viewAll && data?.length === 1;

      setState({
        deployId: shouldSetDefaultValue ? data?.[0]?.deploy_id : null,
        deployType: shouldSetDefaultValue ? data?.[0]?.deploy_type : null,
      });
    },
  });

  const deploymentOptions = React.useMemo(
    () => getDeploymentOptions(deployments, state.deployType),
    [deployments, state.deployType]
  );

  const deployTypeOptions = React.useMemo(
    () =>
      Array.from(new Set((deployments ?? []).map((d) => d.deploy_type))).reduce(
        (acc, curr) => {
          switch (curr) {
            case DeployType.Facebook:
              return [...acc, { label: 'Facebook', value: '1' }];
            case DeployType.Webchat:
              return [...acc, { label: 'Webchat', value: '2' }];
            case DeployType.SalesIQ:
              return [...acc, { label: 'SalesIQ', value: '4' }];
            default:
              return [...acc, { label: 'Others', value: `${curr}` }];
          }
        },
        [] as DashboardContextType['deployTypeOptions']
      ),
    [deployments]
  );

  React.useEffect(() => {
    if (!prevDeployType.current) {
      prevDeployType.current = state.deployType;
      return;
    }

    if (!state.deployType || prevDeployType.current === state.deployType)
      return;

    const defDeployId =
      deploymentOptions.length === 1
        ? Number(
            typeof deploymentOptions[0] === 'object'
              ? deploymentOptions[0].value
              : deployTypeOptions[0]
          )
        : null;

    prevDeployType.current = state.deployType;
    setState({ deployId: defDeployId });
  }, [state.deployType, deploymentOptions, deployTypeOptions, setState]);

  const handleDataOnViewAll = <T extends DataOnViewAll>(data: T) => {
    if (!state.viewAll) return data;

    return {
      ...data,
      agentId: undefined,
      deployId: undefined,
    };
  };

  if (status === 'idle' || status === 'loading')
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <SpinnerLoader />
      </Box>
    );

  return (
    <Context.Provider
      value={{
        filters: state,
        setFilters: setState,
        deploymentOptions,
        deployTypeOptions,
        agentId: selectedAgent?.id!,
        status,
        settings: settingsData ?? { hiddenMetrics: [] },
        isLoadingSettings,
        getDataOnViewAll: handleDataOnViewAll,
      }}
    >
      <Attributes.Provider agentId={agentId!}>{children}</Attributes.Provider>
    </Context.Provider>
  );
};

export const useDashboardContext = () => React.useContext(Context);
