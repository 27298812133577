import { Grid } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';

import { theme } from '@botco/library';
import { routes } from '~/constants/routes';
import { AttributeBreakdown } from '~/containers/Dashboard/AttributeBreakdown';
import { DashboardButtonClicks } from '~/containers/Dashboard/ButtonClicks';
import { DashboardContacts } from '~/containers/Dashboard/Contacts';
import { DataSubmit } from '~/containers/Dashboard/DataSubmit/DataSubmit';
import { DashboardGoalCard } from '~/containers/Dashboard/Goal/DashboardGoalCard';
import { DashboardPopularBlocks } from '~/containers/Dashboard/PopularBlocks';
import { TrafficSource } from '~/containers/Dashboard/TrafficSource';

import { DashboardBlocks } from '../Dashboard/Blocks';
import { PersonalDashboardOverview } from './components/Overview';
import { PersonalDashboardProvider } from './Provider/PersonalDashboardProvider';
import { DashboardAIAnalysis } from '../Dashboard/AIAnalysis';
import { DashboardFilters } from '../Dashboard/Filters/DashboardFilters';
import { DashboardQueryServiceProvider } from '../Dashboard/Provider/DasboardQueryServiceProvider';
import { DashboardProvider } from '../Dashboard/Provider/DashboardProvider';

export const PersonalDashboard = () => {
  return (
    <DashboardProvider>
      <DashboardFilters />
      <DashboardQueryServiceProvider>
        <PersonalDashboardProvider>
          <Grid container spacing={4} style={{ marginTop: theme.spacing(4) }}>
            <Switch>
              <Route path={routes.dashboardBlocks.path} exact>
                <DashboardBlocks />
              </Route>
              <Route path={routes.dashboard.path} exact>
                <Grid item xs={12}>
                  <PersonalDashboardOverview />
                </Grid>
                <Grid item xs={6}>
                  <DashboardGoalCard />
                </Grid>
                <Grid item xs={6}>
                  <DataSubmit />
                </Grid>
                <Grid item xs={12}>
                  <TrafficSource />
                </Grid>
                <Grid item xs={12}>
                  <AttributeBreakdown />
                </Grid>
                <Grid item xs={12}>
                  <DashboardAIAnalysis />
                </Grid>
                <Grid item xs={12}>
                  <DashboardContacts />
                </Grid>
                <Grid item xs={6}>
                  <DashboardPopularBlocks />
                </Grid>
                <Grid item xs={6}>
                  <DashboardButtonClicks />
                </Grid>
              </Route>
            </Switch>
          </Grid>
        </PersonalDashboardProvider>
      </DashboardQueryServiceProvider>
    </DashboardProvider>
  );
};
