import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import * as React from 'react';

import { Select, SelectOption, Typography } from '@botco/library';
import { useQueryServiceApi } from '~/hooks/useQueryServiceApi';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';
import { QueryServiceMetricsConfig } from '~/types/query-service-helpers';

import { AIAnalysisChart } from './components/AIAnalysisChart';
import { DashboardCard } from '../components/Card/DashboardCard';
import { useDashboardQueryServiceContext } from '../Provider/DasboardQueryServiceProvider';

export const DashboardAIAnalysis: React.FC = () => {
  const { conditions } = useDashboardQueryServiceContext();
  const { selectedAgent } = useSelectedChatbot();
  const [selectedTag, setSelectedTag] = React.useState<string | undefined>();
  const [tagsOptions, setTagsOptions] = React.useState<SelectOption[]>([]);

  useQueryServiceApi(
    ['getUserTags', selectedAgent?.id],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: {
            customer_account: {
              external_id: selectedAgent?.account_id,
              chatbot_definitions: [{ external_id: selectedAgent?.id }],
            },
          },
          projection: [{ metric: 'user_tags', views: ['counts_by_tag_label'] }],
        },
      }),
    {
      onSuccess: (data) => {
        const tagsLabels = data?.user_tags?.content?.counts_by_tag_label ?? [];

        setTagsOptions(
          [...new Set(tagsLabels.map((t) => t.tag))].map((tag) => ({
            label: tag!,
            value: tag!,
          }))
        );
      },
    }
  );

  const { isLoading, data } = useQueryServiceApi(
    ['dashboard', 'v2', conditions, 'tag', selectedTag],
    (Api) =>
      Api.query.query({
        requestBody: {
          conditions: conditions!,
          projection: [
            {
              ...QueryServiceMetricsConfig.UserTags,
              options: { 'metric/tags': selectedTag! },
            },
          ],
        },
      }),
    { enabled: Boolean(selectedTag && conditions) }
  );

  const result =
    data?.user_tags?.content?.counts_by_tag_label?.map((d) => ({
      x: d.label!,
      y: d.count!,
    })) ?? [];

  return (
    <DashboardCard title="AI Analysis">
      <Box maxWidth="300px">
        <Select
          fullWidth
          placeholder="Select Tag"
          disabled={isLoading}
          options={tagsOptions}
          current={selectedTag ?? ''}
          onChange={setSelectedTag}
        />
      </Box>
      {isLoading && (
        <Box mt={2}>
          <Skeleton variant="rect" height={350} width="100%" />
        </Box>
      )}
      {!isLoading && (
        <>
          {result.length === 0 && (
            <Typography
              align="center"
              fontStyle={selectedTag ? 'initial' : 'italic'}
            >
              {selectedTag ? 'No data available' : 'Select a tag to view data'}
            </Typography>
          )}
          {selectedTag && <AIAnalysisChart data={result} />}
        </>
      )}
    </DashboardCard>
  );
};
