//
// URL Constants from Environment Variables in .env files.
// DO NOT EDIT VALUES HERE !!!!!!!!!!!!!!!!
//

export const constants = {
  app_env: import.meta.env.VITE_ENV,
  api_url: import.meta.env.VITE_API_URL_NEW,
  query_service_url: import.meta.env.VITE_QUERY_SERVICE_URL,
  firebaseUrl: import.meta.env.VITE_FIREBASE_URL,
  url: import.meta.env.VITE_APIURL,
  fbappid: import.meta.env.VITE_FB_APP_ID,
  fbapivers: import.meta.env.VITE_FB_API_VERS,
  widgetUrl: import.meta.env.VITE_WIDGET_URL,
  webchatTestUrl: import.meta.env.VITE_WEBCHAT_TEST_URL,
  helpCenterUrl: 'https://botco-help-center.super.site/',
} as const;
