import { Box, TablePagination, useTheme } from '@material-ui/core';

import { Table, TableCell, Typography } from '@botco/library';
import { AttributesInput } from '~/components/Attributes/AttributesInput';
import { TableSkeleton } from '~/components/TableSkeleton';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';

import { AttributeBreakdownRow } from './components/AttributeBreakdownRow';
import { PAGE_SIZE, useAttributeBreakdown } from './useAttributeBreakdown';
import { DashboardCard } from '../components/Card/DashboardCard';

export const AttributeBreakdown = () => {
  const theme = useTheme();
  const { selectedAgent } = useSelectedChatbot();

  const {
    attributeValues,
    goals,
    isLoadingAttrValues,
    page,
    selectedAttribute,
    setPage,
    setSelectedAttribute,
    totalItems,
  } = useAttributeBreakdown();

  return (
    <DashboardCard title="Attribute Breakdown">
      <Table
        data={attributeValues}
        getKey={(row) => `${row.value}-${row.count}`}
        variant="transparent"
        headerColumns={
          <>
            <TableCell width={theme.spacing(33)}>
              <Box minWidth={theme.spacing(33)}>
                <AttributesInput
                  agentId={selectedAgent?.id!}
                  disableCreate
                  value={selectedAttribute ?? {}}
                  placeholder="Select Attribute"
                  onChange={setSelectedAttribute}
                />
              </Box>
            </TableCell>
            <TableCell>Unique Chatbot Views</TableCell>
            <TableCell align="center">Engaged Users</TableCell>
            <TableCell>Engagement Rate</TableCell>
            <TableCell align="center">Success Rate</TableCell>
            {goals.map((goal) => (
              <TableCell key={goal} align="center">
                {goal}
              </TableCell>
            ))}
            <TableCell align="center">Calls</TableCell>
          </>
        }
        renderRow={({ value }) => (
          <AttributeBreakdownRow
            key={value}
            value={value!}
            goals={goals}
            filteredBy={selectedAttribute?.cust_attr_name ?? ''}
          />
        )}
      >
        {isLoadingAttrValues && <TableSkeleton columns={6 + goals.length} />}
      </Table>
      {totalItems > 0 && (
        <TablePagination
          rowsPerPageOptions={[]}
          count={totalItems}
          colSpan={5}
          component="div"
          rowsPerPage={PAGE_SIZE}
          page={page - 1}
          onPageChange={(_, page) => setPage(page + 1)}
        />
      )}
      {!isLoadingAttrValues && attributeValues.length === 0 && (
        <Box mt={2}>
          <Typography
            align="center"
            fontStyle={selectedAttribute ? undefined : 'italic'}
          >
            {selectedAttribute
              ? 'No Data Available'
              : 'Select an attribute to view data'}
          </Typography>
        </Box>
      )}
    </DashboardCard>
  );
};
