import { makeStyles } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as React from 'react';

import { Typography } from '@botco/library';
import { WorkOSOrganization } from '~/utils/http/workos/types';

type Props = {
  organizations: WorkOSOrganization[];
  isLoading: boolean;
  onSelectOrg: (orgId: string) => void;
};

export const WorkOSOrganizations: React.FC<Props> = ({
  organizations,
  isLoading,
  onSelectOrg,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const classes = useStyles();

  const handleArrowNavigation = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const buttons = containerRef.current?.querySelectorAll('button');

    if (!buttons) return;

    const currentIndex = Array.from(buttons).indexOf(
      document.activeElement as HTMLButtonElement
    );

    if (e.key === 'ArrowDown') {
      const nextIndex = (currentIndex + 1) % buttons.length;
      buttons[nextIndex].focus();
      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      const prevIndex = (currentIndex - 1 + buttons.length) % buttons.length;
      buttons[prevIndex].focus();
      e.preventDefault();
    }
  };

  return (
    <div
      className={classes.container}
      ref={containerRef}
      onKeyDown={handleArrowNavigation}
    >
      {organizations.map((org) => (
        <button
          key={org.id}
          tabIndex={0}
          className={classes.item}
          onClick={() => onSelectOrg(org.id)}
        >
          <Typography fontWeight={600}>{org.name}</Typography>
          <ChevronRightIcon />
        </button>
      ))}
      {!isLoading && organizations.length === 0 && (
        <Typography align="center" paragraph>
          No organizations found
        </Typography>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: 100,

    '& button:not(:first-child)': {
      borderTop: `1px solid ${theme.palette.grey[600]}`,
    },

    '& button:last-child': {
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 12,
    },
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 56,
    minHeight: 56,
    userSelect: 'none',
    backgroundColor: theme.palette.common.white,
    outline: 'none',
    border: 'none',
    padding: theme.spacing(1),
    justifyContent: 'space-between',

    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[200],
    },
  },
}));
