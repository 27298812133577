import { paths } from '~/__generated__/botco-api-schema';
import {
  ExtractPathParamsFromPath,
  ExtractQueryParamsFromPath,
} from '~/types/open-api-helpers';

import { axiosClient } from '../axiosClient';
import { WorkOSOrganization } from './types';

type PathUrl = paths['/users/{userId}/workos-membership']['get'];
type Params = ExtractQueryParamsFromPath<PathUrl> &
  ExtractPathParamsFromPath<PathUrl>;

export async function getUserOrganizations({ userId, workos_user_id }: Params) {
  return axiosClient.get<WorkOSOrganization[]>(
    `/users/${userId}/workos-membership`,
    { params: { workos_user_id } }
  );
}
