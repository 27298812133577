import { useSelector } from 'react-redux';

import {
  CreatableDropDown,
  ICreatableDropDownOption,
  ICreatableDropDownProps,
} from '@botco/library';
import { isValidNewOption } from '~/utils';
import { IntentType } from '~/utils/http/agent/types';
import * as yup from '~/utils/yup';

type BaseProps = Pick<
  ICreatableDropDownProps,
  | 'error'
  | 'helperText'
  | 'label'
  | 'placeholder'
  | 'disableCreateOption'
  | 'dataTest'
>;

type Props = BaseProps & {
  blockId: number | null | undefined;
  onChange: (blockId: number | undefined) => void;
  onCreateOption?: ICreatableDropDownProps['onCreateOption'];
};

export const BlocksDropdown = ({
  blockId,
  label,
  dataTest,
  onChange,
  onCreateOption = () => {},
  placeholder,
  error,
  helperText,
  disableCreateOption,
}: Props) => {
  const blocks = useSelector(({ conversationsDataR }) =>
    conversationsDataR.allBlocks.filter((b) => b.type !== IntentType.INACTIVITY)
  );

  const handleChange = (option: ICreatableDropDownOption) => {
    const value = yup.safeNumber().cast(option.value, { assert: false });
    onChange(value);
  };

  return (
    <CreatableDropDown
      current={
        blockId
          ? ((blocks.find(
              (d) => d.value === blockId
            ) as unknown as ICreatableDropDownOption) ?? '')
          : { label: '', value: '' }
      }
      label={label}
      dataTest={dataTest}
      placeholder={placeholder}
      options={blocks as unknown as ICreatableDropDownOption[]}
      isValidNewOption={isValidNewOption}
      onCreateOption={onCreateOption}
      onChange={handleChange}
      error={error}
      helperText={helperText}
      disableCreateOption={disableCreateOption}
      formatCreateLabel={(inputValue) => `Create "${inputValue}" Block`}
    />
  );
};
