import * as React from 'react';

import {
  AttributeConditionsDto,
  QueryConditionsDto,
  QueryResultsDto,
} from '~/__generated__/query-service';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useSelectedChatbot } from '~/hooks/useSelectedChatbot';

import { useDashboardContext } from './DashboardProvider';

export type QueryServiceData = QueryResultsDto;

type DashboardQueryServiceContextType = {
  conditions?: QueryConditionsDto;
};

const DashboardQueryServiceContext =
  React.createContext<DashboardQueryServiceContextType>({
    conditions: undefined,
  });

export const DashboardQueryServiceProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const user = useCurrentUser();
  const { selectedAgent } = useSelectedChatbot();
  const { filters: state } = useDashboardContext();

  const attributes = ((): AttributeConditionsDto | undefined => {
    if (!state.attributes || !state.attributes.conditions?.length)
      return undefined;

    return {
      conditions: state.attributes.conditions
        .filter((c) => Boolean(c.value))
        .map((condition) => ({
          name: condition.attribute?.cust_attr_name!,
          operator: condition.operator,
          value: condition.value!,
        })),
      logical_operator: state.attributes.logical_operator,
    };
  })();

  const conditions = {
    datetime_range: {
      from: state.startTime,
      until: state.endTime,
      from_inclusive: true,
      until_inclusive: true,
    },
    customer_account: {
      external_id: user.account_id,
      chatbot_definitions: [
        {
          external_id: selectedAgent?.id,
          chatbot_instances: state.deployId
            ? [{ external_id: state.deployId }]
            : [],
        },
      ],
    },
    ...(state.channel && { channel: state.channel }),
    ...(attributes?.conditions?.length && { attributes }),
  } satisfies QueryConditionsDto;

  return (
    <DashboardQueryServiceContext.Provider value={{ conditions }}>
      {children}
    </DashboardQueryServiceContext.Provider>
  );
};

export const useDashboardQueryServiceContext = () =>
  React.useContext(DashboardQueryServiceContext);
