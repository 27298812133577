import ReactApexChart from 'react-apexcharts';

import { theme } from '@botco/library';

type Props = {
  data: Array<{ x: string; y: number }>;
};

export const AIAnalysisChart: React.FC<Props> = ({ data }) => {
  const max = Math.max(...data.map((item) => item.y));

  const options: ApexCharts.ApexOptions = {
    chart: {
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: true,
      offsetY: -4,
      style: { fontSize: '13px', colors: ['#212121'] },
      formatter: (text, op) => [text, op.value] as any,
    },
    states: {
      hover: { filter: { type: 'none' } },
      active: { filter: { type: 'none' } },
    },
    plotOptions: {
      treemap: {
        colorScale: {
          ranges: [
            { from: max, to: max, color: theme.palette.primary.main },
            { from: 0, to: max - 1, color: theme.palette.secondary.main },
          ],
        },
        dataLabels: { format: 'truncate' },
      },
    },
    tooltip: { enabled: true },
  };

  return (
    <ReactApexChart
      options={options}
      series={[{ data }]}
      type="treemap"
      height={350}
    />
  );
};
