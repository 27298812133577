import { evaluate, format as mathjsFormat } from 'mathjs';

import { QueryResultsDto } from '~/__generated__/query-service';
import { METRICS_CARDS } from '~/containers/Dashboard/OverView/constants';
import { OverviewMetric } from '~/utils/http/dashboard/types';

export const getFormulaOverviewData = (
  formula: string,
  data: QueryResultsDto | undefined,
  format?: Record<string, unknown>
) => {
  if (!data) {
    return {
      overview: 0,
      details: [],
    };
  }

  const metricIds =
    formula.match(/#\w+/g)?.map((m) => m.replaceAll('#', '')) ?? [];

  const metricsData: Record<string, number> = metricIds.reduce(
    (acc, metricId) => {
      const metricConfig = METRICS_CARDS.find((m) => m.id === metricId);

      if (!metricConfig) {
        throw new Error(`Metric with id ${metricId} not found`);
      }

      return {
        ...acc,
        [metricId]: metricConfig.getValue(data) ?? 0,
      };
    },
    {} as Record<string, number>
  );

  const cleanFormula = formula.replaceAll('#', '');

  const result = evaluate(
    cleanFormula,
    metricIds.reduce(
      (acc, metricId) => ({
        ...acc,
        [metricId]: metricsData[metricId],
      }),
      {}
    )
  );

  return format ? mathjsFormat(result, format) : result;
};

export const getFormulaChartData = (
  formula: string,
  data: QueryResultsDto | undefined
): OverviewMetric => {
  if (!data) {
    return {
      overview: 0,
      details: [],
    };
  }

  const metricIds =
    formula.match(/#\w+/g)?.map((m) => m.replaceAll('#', '')) ?? [];

  const metricsData: Record<string, OverviewMetric> = metricIds.reduce(
    (acc, metricId) => {
      const metricConfig = METRICS_CARDS.find((m) => m.id === metricId);

      if (!metricConfig) {
        throw new Error(`Metric with id ${metricId} not found`);
      }

      return {
        ...acc,
        [metricId]: metricConfig.getCurrentData(data),
      };
    },
    {} as Record<string, OverviewMetric>
  );

  const cleanFormula = formula.replaceAll('#', '');
  const key =
    Object.entries(metricsData).find(
      ([_key, value]) => value.details.length > 0
    )?.[0] ?? metricIds[0];

  const details: OverviewMetric['details'] = metricsData[key].details.map(
    (d, i) => {
      const formulaData = metricIds.reduce(
        (acc, metricId) => ({
          ...acc,
          [metricId]: metricsData[metricId].details[i]?.value ?? 0,
        }),
        {}
      );

      const value = evaluate(cleanFormula, formulaData);

      return {
        date: d.date,
        value: Number.isNaN(value) || !Number.isFinite(value) ? 0 : value,
      };
    }
  );

  const overview: OverviewMetric['overview'] = evaluate(
    cleanFormula,
    metricIds.reduce(
      (acc, metricId) => ({
        ...acc,
        [metricId]: metricsData[metricId].overview,
      }),
      {}
    )
  );

  return {
    overview: Number.isNaN(overview) ? 0 : overview,
    details,
  };
};
