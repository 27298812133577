import { QueryServiceMetricsConfigKey } from '~/types/query-service-helpers';
import { OverviewMetric } from '~/utils/http/dashboard/types';
import {
  formatCurrency,
  formatNumber,
  formatPercentage,
} from '~/utils/numberUtils';

import { QueryServiceData } from '../Provider/DasboardQueryServiceProvider';

export type MetricCardType = {
  id: QueryServiceMetricsConfigKey;
  title: string;
  tooltip: string;
  hidden?: boolean;
  shouldShowNew?: boolean;
  getValue: (data?: QueryServiceData) => number | undefined;
  getCurrentData: (data?: QueryServiceData) => OverviewMetric;
  formatFn?: (value: number) => string;
};

export const METRICS_CARDS = [
  {
    id: 'Views',
    title: 'Chatbot Views',
    tooltip: 'The number of users that viewed the chatbot.',
    formatFn: formatNumber,
    getValue: (data) => data?.widget_views?.count,
    getCurrentData: (data) => ({
      overview: data?.widget_views?.count ?? 0,
      details:
        data?.widget_views?.content?.counts_by_date?.map(({ count, date }) => ({
          date: date!,
          value: count ?? 0,
        })) ?? [],
    }),
  },
  {
    id: 'UniqueViews',
    title: 'Unique Chatbot Views',
    tooltip: 'The number of unique users that viewed the chatbot.',
    formatFn: formatNumber,
    getValue: (data) => data?.widget_views?.count ?? 0,
    getCurrentData: (data) => ({
      overview: data?.widget_views?.count ?? 0,
      details:
        data?.widget_views?.content?.counts_by_date?.map(({ count, date }) => ({
          date: date!,
          value: count ?? 0,
        })) ?? [],
    }),
  },
  {
    id: 'EngagedUsers',
    title: 'Engaged Users',
    tooltip:
      'The number of unique users that interacted with the chatbot, they opened it and engaged with it through a message or a button click.',
    shouldShowNew: true,
    formatFn: formatNumber,
    getValue: (data) => data?.users?.count,
    getCurrentData: (data) => ({
      overview: data?.users?.count ?? 0,
      details:
        data?.users?.content?.counts_by_date?.map(({ date, count }) => ({
          date: date!,
          value: count ?? 0,
        })) ?? [],
    }),
  },
  {
    id: 'EngagementRate',
    title: 'Engagement rate',
    tooltip:
      'The rate at which unique users engaged in a session (Engaged Sessions / Unique Chatbot Views x 100%).',
    formatFn: formatPercentage,
    getValue: (data) =>
      data?.calculations?.content?.engagement_rate?.engagement_rate,
    getCurrentData: (data) => ({
      overview:
        data?.calculations?.content?.engagement_rate?.engagement_rate ?? 0,
      details:
        data?.calculations?.content?.engagement_rate?.engagement_rates_by_date?.map(
          ({ date, engagement_rate }) => ({
            date: date!,
            value: engagement_rate ?? 0,
          })
        ) ?? [],
    }),
  },
  {
    id: 'SuccessRate',
    title: 'Success Rate',
    tooltip:
      ' The rate at which the chatbot successfully responded to users (No Match block visits / User inputs * 100%).',
    formatFn: formatPercentage,
    shouldShowNew: true,
    getValue: (data) => data?.calculations?.content?.success_rate?.success_rate,
    getCurrentData: (data) => ({
      overview: data?.calculations?.content?.success_rate?.success_rate ?? 0,
      details:
        data?.calculations?.content?.success_rate?.success_rates_by_date?.map(
          ({ date, success_rate }) => ({
            date: date!,
            value: success_rate ?? 100,
          })
        ) ?? [],
    }),
  },
  {
    id: 'Goals',
    title: 'Goals',
    tooltip:
      'The number of times a chatbot user successfully completed a set goal within the conversation.',
    formatFn: (value) => `$${formatCurrency(value)}`,
    getValue: (data) => data?.goals?.value,
    getCurrentData: (data) => ({
      overview: data?.goals?.value ?? 0,
      details:
        data?.goals?.content?.values_by_date?.map(({ value, date }) => ({
          date: date!,
          value: value ?? 0,
        })) ?? [],
    }),
  },
  {
    id: 'GoalsCount',
    title: 'Goals',
    tooltip:
      'The number of times a chatbot user successfully completed a set goal within the conversation.',
    hidden: true,
    getValue: (data) => data?.goals?.count,
    getCurrentData: (data) => ({
      overview: data?.goals?.count ?? 0,
      details:
        data?.goals?.content?.counts_by_date?.map(({ count, date }) => ({
          date: date!,
          value: count ?? 0,
        })) ?? [],
    }),
  },
  {
    id: 'DataSubmits',
    title: 'Data Submits',
    tooltip:
      'The number of times a chat sent data to another system like a CRM, EHR, email or other.',
    getValue: (data) => data?.data_submits?.count,
    getCurrentData: (data) => ({
      overview: data?.data_submits?.count ?? 0,
      details:
        data?.data_submits?.content?.counts_by_date?.map(({ count, date }) => ({
          date: date!,
          value: count ?? 0,
        })) ?? [],
    }),
  },
  {
    id: 'Calls',
    title: 'Calls',
    tooltip:
      'The number of times a user started a call within the chat by clicking a call button.',
    getValue: (data) => data?.widget_clicks?.count,
    getCurrentData: (data) => ({
      overview: data?.widget_clicks?.count ?? 0,
      details:
        data?.widget_clicks?.content?.counts_by_date?.map(
          ({ date, count }) => ({
            date: date!,
            value: count ?? 0,
          })
        ) ?? [],
    }),
  },
  {
    id: 'LiveChats',
    title: 'Handovers',
    tooltip:
      'The number of times the chatbot successfully transferred the conversation to a live agent.',
    getValue: (data) => data?.live_chats?.count,
    getCurrentData: (data) => ({
      overview: data?.live_chats?.count ?? 0,
      details:
        data?.live_chats?.content?.counts_by_date?.map(({ count, date }) => ({
          date: date!,
          value: count ?? 0,
        })) ?? [],
    }),
  },
  {
    id: 'AverageSMSSessionDuration',
    title: 'Average SMS Session Duration',
    tooltip: 'The average duration of a conversation in SMS',
    hidden: true,
    getValue: (data) => {
      const count =
        data?.calculations?.content?.average_session_duration
          ?.average_duration_seconds;

      if (count != null) {
        return Number(count.toFixed(2));
      }

      return undefined;
    },
    getCurrentData: (data) => ({
      overview:
        data?.calculations?.content?.average_session_duration
          ?.average_duration_seconds ?? 0,
      details:
        data?.calculations?.content?.average_session_duration?.average_durations_by_date?.map(
          ({ date, average_duration_seconds }) => ({
            date: date!,
            value: average_duration_seconds ?? 0,
          })
        ) ?? [],
    }),
  },
  {
    id: 'AverageWebchatSessionDuration',
    title: 'Average Webchat Session Duration',
    tooltip: 'The average duration of a conversation in Webchat',
    hidden: true,
    getValue: (data) => {
      const count =
        data?.calculations?.content?.average_session_duration
          ?.average_duration_seconds;

      if (count != null) {
        return Number(count.toFixed(2));
      }

      return undefined;
    },
    getCurrentData: (data) => ({
      overview:
        data?.calculations?.content?.average_session_duration
          ?.average_duration_seconds ?? 0,
      details:
        data?.calculations?.content?.average_session_duration?.average_durations_by_date?.map(
          ({ date, average_duration_seconds }) => ({
            date: date!,
            value: average_duration_seconds ?? 0,
          })
        ) ?? [],
    }),
  },
  {
    id: 'AverageEngagedUserMessages',
    title: 'Average Engaged User Messages',
    tooltip: 'The average number of messages sent by engaged users',
    hidden: true,
    getValue: (data) => {
      const count =
        data?.calculations?.content?.average_engaged_user_messages
          ?.calculated_value;

      if (count != null) {
        return Number(count.toFixed(2));
      }

      return undefined;
    },
    getCurrentData: (data) => ({
      overview:
        data?.calculations?.content?.average_engaged_user_messages
          ?.calculated_value ?? 0,
      details:
        data?.calculations?.content?.average_engaged_user_messages?.by_date?.map(
          ({ date, calculated_value }) => ({
            date: date!,
            value: calculated_value ?? 0,
          })
        ) ?? [],
    }),
  },
  {
    id: 'TotalMessagesByChatbot',
    title: 'Total Messages By Chatbot',
    tooltip: 'Total messages sent by chatbot',
    getValue: (data) => data?.sessions?.count,
    hidden: true,
    getCurrentData: (data) => ({
      overview: data?.sessions?.count ?? 0,
      details:
        data?.sessions?.content?.message_counts_by_date?.map(
          ({ date, chatbot_message_count }) => ({
            date: date!,
            value: chatbot_message_count ?? 0,
          })
        ) ?? [],
    }),
  },
  {
    id: 'TotalMessagesByUser',
    title: 'Total Messages By User',
    tooltip: "Total messages sent by user's",
    hidden: true,
    getValue: (data) => data?.sessions?.count,
    getCurrentData: (data) => ({
      overview: data?.sessions?.count ?? 0,
      details:
        data?.sessions?.content?.message_counts_by_date?.map(
          ({ date, user_message_count }) => ({
            date: date!,
            value: user_message_count ?? 0,
          })
        ) ?? [],
    }),
  },
] satisfies Array<MetricCardType>;
